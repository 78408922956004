@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic:wght@400;700&display=swap');

html {
  font-size: 8px;
}

@media screen and (min-width: 640px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 1008px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1920px) {
  html {
    font-size: 24px;
  }
}

@media screen and (min-width: 2400px) {
  html {
    font-size: 32px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'BIZ UDPGothic', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #3E2723;
}

::-webkit-scrollbar {
  display: none;
}

.text-primary-light {
  color: #8d6e63;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}